<!--营养品知识设置  -->
<template>
	<div>
		<div>
			<div class="child-top">
				<div class="searchArea input-from please">
					<el-input class="input-with-select please" clearable placeholder="请输入分类名称" style="width: 200px" suffix-icon="iconfont iconsousuo" v-model="keyword"></el-input>
					<el-button class="searchBtn" @click="getData(1)" round>搜索</el-button>
				</div>
				<div class="operation-button">
					<el-button class="new_btn" @click="addButton()" :disabled="$store.getters.permissionsStr('新增')" round>
						<span>新增</span>
					</el-button>
					<el-button class="del_btn" @click="someDels" :disabled="$store.getters.permissionsStr('删除') || !sels.length" round>
						<span>删除</span>
					</el-button>
				</div>
			</div>
			<div class="table">
				<el-table
					:empty-text="tableTwo.tableData.length ? '' : '暂无数据'"
					:data="!$store.getters.permissionsStr('列表') ? tableTwo.tableData : []"
					row-key="uuid"
					:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
					v-loading="tableLoading2"
					@selection-change="selsChange"
				>
					<el-table-column type="selection" width="55" sortable></el-table-column>
					<el-table-column label="序号" width="60" type="index"></el-table-column>
					<el-table-column label="营养品分类名称" prop="name" sortable show-overflow-tooltip> </el-table-column>
					<el-table-column label="创建人" prop="create_by" show-overflow-tooltip></el-table-column>
					<el-table-column label="创建时间" prop="create_time" width="220" show-overflow-tooltip></el-table-column>
					<el-table-column label="状态" prop="status" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-switch
								v-show="scope.row.uuid"
								v-model="scope.row.status"
								active-color="#BDC6CF"
								inactive-color="#13ce66"
								:active-value="0"
								:inactive-value="1"
								style="width: 35px"
								@change="changeStatus2(scope.row)"
								:disabled="$store.getters.permissionsStr('启/停用')"
							></el-switch>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="140">
						<template slot-scope="scope">
							<el-button style="color: #2493e7" size="medium" type="text" @click="editButton2(scope.row)" :disabled="$store.getters.permissionsStr('编辑')" v-show="scope.row.uuid"
								>编辑</el-button
							>
							<el-button style="color: #f05756" size="medium" type="text" @click="delTableData(scope.row)" :disabled="$store.getters.permissionsStr('删除')" v-show="scope.row.uuid"
								>删除</el-button
							>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<!-- <el-pagination
          :current-page.sync="page"
          :page-size="limit"
          :total="total"
          @current-change="handleCurrentChange2"
          class="table-pagination"
          layout="total, prev, pager, next , jumper"
        ></el-pagination> -->
			</div>
			<!-- 弹框 -->
			<el-dialog @close="resetForm()" :close-on-click-modal="false" :close-on-press-escape="false" :title="title2" :destroy-on-close="true" :visible.sync="dialogFormVisible2" width="30%">
				<el-form :rules="rules" :model="form2" ref="ruleForm1">
					<el-form-item label="隶属分类" prop="puuid" label-width="100px">
						<!-- :placeholder="form2.zi == '' ? '请选择' : form2.zi" -->
						<el-cascader
							v-model="form2.puuid"
							:options="newData"
							style="width: 100%"
							:placeholder="form2.zi == '' ? '请选择' : form2.zi"
							clearable
							:props="{
								checkStrictly: true,
								value: 'uuid',
								label: 'name',
								emitPath: false,
							}"
						>
						</el-cascader>
					</el-form-item>
					<el-form-item label="分类名称" label-width="100px" prop="name">
						<el-input @input="form2.name = String(form2.name)" v-model="form2.name" clearable></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button
						@click="
							resetForm()
							dialogFormVisible2 = false
						"
						>取 消</el-button
					>
					<el-button type="primary" @click="addForm2()">保存</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
export default {
	name: 'NutritionSettings',
	data() {
		return {
			newData: [],
			parentData: [],
			tableDataSelectArr: [], // 多选
			comp: '', //编辑
			tabActive: true,
			currentPage: 1,
			options1: [],
			disabledBtnTwo: true, //禁用按钮
			selectRowsUuid: [], //选中行的uuid
			addformSet: {},
			timeOptions: [],
			puuid: '',
			rules: {
				name: [
					{
						required: true,
						message: '请输入分类名称',
						trigger: 'blur',
					},
					{
						min: 1,
						max: 20,
						message: '限制在20个汉字以内',
						trigger: 'blur',
					},
					{
						validator: (rule, value, callback) => {
							if (!/[\u4E00-\u9FA5]/g.test(value)) {
								callback(new Error('分类名称只能输入汉字!'))
							} else {
								callback()
							}
						},
						trigger: 'blur',
					},
				],
				puuid: {
					required: true,
					message: '请选择隶属分类',
					trigger: 'blur',
				},

				name: [
					{ required: true, message: '请输入分类名称' },
					{
						min: 1,
						max: 10,
						message: '最大长度10位',
						// trigger: "blur"
					},
				],
			},

			dialogView: false, // 查看数据弹窗状态
			tab: 'record', //标签页
			tableType: 2,
			tableTwo: {
				tableData: [{ uuid: 0 }],
				add: [],
				sels: [], // 列表选中列
				total: 0, // 总数
				page: 1, // 当前分页
				limit: 10, // 每页条数
			},
			sels: [], // 列表选中列
			total: 0, // 总数
			page: 1, // 当前分页
			limit: 10, // 每页条数
			tableData: [], // 表格数据
			keyword: '',
			dialogFormVisible2: false,
			dialogFormVisible1: false,
			form2: {
				uuid: '',
				name: '',
				puuid: [],
				name: '',
				zi: '',
			},
			compile: {
				uuid: '',
				name: '',
				puuid: [],
				name: '',
			},
			handleType2: 1,
			handleType1: 1,
			tableLoading2: false,
			tableLoading1: false,
			options: [],
			formsearch: {
				uuid: '',
				keywords: '',
			},
			fileList: [],
			info: {},
			title1: '',
			title2: '',
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.addformSet = { ...this.addform }
			this.getData(1)
			this.useTimeList()
		})
	},
	watch: {},
	methods: {
		someDels() {
			let that = this
			//删除数据
			if (that.sels.length == 0) {
				that.$message({
					message: '请选择要删除的数据',
					type: 'error',
					duration: 4000,
				})
				return
			}
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'success',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let params = []
				let url = '/p/articleCate/del'
				for (let i = 0; i < this.sels.length; i++) {
					params[i] = this.sels[i].uuid
				}
				let ids = params.join(',')
				let data = { uuids: ids, type: 1 }
				this.$axios.post(url, this.$qs(data)).then(res => {
					if (res.data.code == 0) {
						this.getData()
						this.$message({
							message: res.data.message,
							type: 'success',
						})
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
			})
		},
		// 单行删除
		delTableData(row) {
			this.$confirm('确定删除数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/articleCate/del',
						this.$qs({
							uuids: row.uuid,
							type: 1,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							this.getData(this.currentPage)
							// this.$router.go(0);
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {})
			})
		},
		// //编辑表单中表格的复选框选中与取消
		selsChange(sels) {
			this.sels = sels
		},

		handleCurrentChange2(val) {
			this.tableTwo.page = val
			this.getData()
		},
		//列表
		getData(run_type = 0) {
			if (run_type == 1) {
				this.tableTwo.page = 1
			}
			let params = {
				page: this.page,
				page_size: this.limit,
				keyword: this.keyword,
				type: 1,
			}
			//获取列表
			let url = '/p/articleCate/lists'
			this.tableLoading2 = true
			this.$axios
				.post(url, this.$qs(params))
				.then(response => {
					if (response.data.code == 0) {
						this.tableTwo.tableData = response.data.data
						this.tableTwo.total = response.data.total
						this.tableLoading2 = false
					} else {
						this.$message({
							message: response.message,
							type: 'error',
						})
					}
					this.tableLoading2 = false
				})
				.catch(error => {
					this.tableLoading2 = false
					// console.log(error);
				})
		},

		addButton() {
			sessionStorage.setItem('arch_search', JSON.stringify({ keyword: this.keyword }))

			this.getData()
			this.useTimeList()
			this.handleType2 = 1
			this.title2 = '新增营养知识分类'
			this.dialogFormVisible2 = true
			// this.form2.zi = "顶级分类";
			return false
		},
		useTimeList() {
			let url = '/p/articleCate/all'
			this.$axios
				.post(url, this.$qs({ type: 1, flag: 1, uuid: this.form2.uuid }))
				.then(response => {
					if (response.data.code == 0) {
						this.timeOptions = response.data.data
						this.parentData = response.data.data
						this.parentData.unshift({
							name: '顶级分类',
							uuid: '0',
							key: '0',
							puuid: '0',
							level: 1,
						})
						/*  让无限极联动器 到第3级  */
						let arr = JSON.parse(JSON.stringify(response.data.data))
						let aa = []
						arr.forEach((item, idx) => {
							if (item.children && item.children.length) {
								item.children.forEach((itm, ix) => {
									if (itm.children && itm.children.length) {
										delete itm.children
									}
								})
							}
							// this.newData.push(item);
							aa.push(item)
							this.newData = [...new Set(aa)]
						})
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}
				})
				.catch(error => {})
		},

		view(data) {
			this.info = {}
			let url = '/p/articleCate/info'
			let params = {
				uuid: data.uuid,
			}
			this.$axios.post(url, this.$qs(params)).then(response => {
				if (response.data.code == 0) {
					this.info = response.data
				} else {
					this.$message({
						message: response.message,
						type: 'error',
					})
				}
			})
			this.dialogView = true
		},
		editButton2(data) {
			this.handleType2 = 2
			this.form2.name = data.name
			this.form2.zi = '顶级分类'
			this.form2.uuid = data.uuid
			this.form2.puuid = data.puuid
			this.form2.name = String(data.name)
			this.title2 = '编辑营养知识分类'
			this.dialogFormVisible2 = true
			this.compile.name = data.name
			this.useTimeList()
		},
		resetForm() {
			this.form2.name = ''
			this.form2.name = ''
			this.form2.uuid = ''
			this.form2.puuid = []
			this.form2.zi = ''
		},
		addForm2() {
			if (this.handleType2 === 1) {
				let url = '/p/articleCate/add'
				let params = {
					name: this.form2.name,
					puuid: this.form2.puuid,
					// puuid: this.form2.puuid.toString(","),
					type: 1,
				}
				this.$refs.ruleForm1.validate(valid => {
					if (valid) {
						this.$axios.post(url, this.$qs(params)).then(response => {
							if (response.data.code === 0) {
								this.dialogFormVisible2 = false
								this.resetForm()
								this.getData()
								this.$message({
									message: response.data.message,
									type: 'success',
								})
								// this.$router.go(0);
							} else {
								this.$message({
									message: response.data.message,
									type: 'error',
								})
							}
						})
					}
				})
			} else {
				let url = '/p/articleCate/update'
				let params = {
					name: this.form2.name,
					uuid: this.form2.uuid,
					// name: this.form2.name,
					puuid: this.form2.puuid,
					// puuid: this.form2.puuid.toString(","),
					type: 1,
				}

				this.$refs.ruleForm1.validate(valid => {
					if (valid) {
						this.$axios.post(url, this.$qs(params)).then(response => {
							// 更新成功
							if (response.data.code === 0) {
								this.dialogFormVisible2 = false
								this.resetForm()
								this.getData()
								this.$message({
									message: response.data.message,
									type: 'success',
								})
								// this.$router.go(0);
							} else {
								// 更新失败
								this.$message({
									message: response.data.message,
									type: 'error',
								})
							}
						})
					}
				})
			}
		},

		fenl() {
			this.useTimeList()
		},
		changeStatus2(data) {
			let params = {
				uuid: data.uuid,
			}
			//获取列表
			let url = '/p/articleCate/able'
			this.$axios
				.post(url, this.$qs(params))
				.then(response => {
					if (response.data.code == 0) {
						this.$message({
							message: '切换成功',
							type: 'success',
						})
						this.getData(this.currentPage)
					}
				})
				.catch(error => {
					// console.log(error);
				})
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/ .search-box {
	& /deep/ .el-input__inner {
		border-top-left-radius: 4px !important;
		border-bottom-left-radius: 4px !important;
	}
}

.wrap {
	padding-right: 10px;

	.topOperate {
		height: 67px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 10px;
		border-radius: 2px 2px 0px 0px;
		padding: 0.52083rem 1.04167rem;
		margin-bottom: 0.52083rem;
		background: #fff;
		.queryArea {
			margin-bottom: 0;

			.filterArea {
				/*margin: 10px 0;*/
				flex: 1;
				display: flex;
				align-items: center;

				.el-cascader,
				.el-date-editor,
				.el-select {
					/*margin: 10px 20px 10px 0;*/
					margin-right: 20px;
				}

				.selectInput {
					width: 220px;
				}
			}
		}
		.search-btn-area {
			.search-btn {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.btn {
					display: flex;
					justify-content: flex-start;
				}

				.search-box {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
		}
	}
}

/deep/.el-input-group--append .el-input__inner,
.el-input-group__prepend {
	border-radius: 0;
}
body {
	padding: 0;
	margin: 0 auto;
}

ul {
	list-style: none;
}

#tab {
	width: 100%;
	height: 70px;
	background: #ffffff;
	box-shadow: 0px 2px 8px 0px #efefef;
}

#title li {
	width: 134px;
	float: left;
	line-height: 70px;
	text-align: center;
	color: #333333;
}

#title .select {
	width: 134px;
	height: 67px;
	border-bottom: 3px solid #2493e7;
}
#back {
	width: 110px;
	height: 38px;
	background: #ffffff;
	box-shadow: 0 2px 8px 0 #efefef;
	border-radius: 19px;
	margin-right: 30px;
	float: right;
	text-align: center;
	margin-top: 16px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 38px;
}

#content {
	padding: 41px;
	background: #ffffff;
	box-shadow: 2px 2px 8px 0px #efefef;
	border-radius: 2px;
}

#tit {
	width: 100%;
	height: 35px;
	font-size: 26px;
	font-family: MicrosoftYaHei;
	color: #333333;
	line-height: 35px;
	text-align: center;
	margin-top: 31px;
}

.buttonText[data-v-563acab6] {
	color: #373737;
}

.tips {
	width: 6px;
	height: 20px;
	background: #2493e7;
	border-radius: 3px;
	margin-left: 87px;
	margin-top: 31px;
}

.input {
	width: 350px;
	height: 48px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	padding-left: 10px;
}

#fo li {
	width: 350px;
	float: left;
	margin-left: 120px;
	font-size: 16px;
	color: #666666;
	margin-top: 20px;
	margin-right: 70px;
}

.bt {
	color: #f73838;
	margin-right: 2px;
}

.sel {
	width: 120px;
	height: 48px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
}

#bot {
	padding: 41px;
	height: 200px;
	background: #ffffff;
	box-shadow: 2px 2px 8px 0px #efefef;
	border-radius: 2px;
}

.in {
	width: 90%;
	height: 48px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	margin-right: 60px;
}

.ti {
	width: 119px;
	height: 21px;
	font-size: 16px;
	font-family: MicrosoftYaHei;
	color: #666666;
	line-height: 21px;
	text-align: inherit;
	margin-left: 60px;
}

#sub {
	width: 120px;
	height: 48px;
	background: #2493e7;
	border-radius: 2px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

#sub span {
	width: 32px;
	height: 21px;
	font-size: 16px;
	font-family: MicrosoftYaHei;
	color: #ffffff;
	line-height: 48px;
}

.add-content {
	.add-content-title {
		height: 35px;
		font-size: 26px;
		font-family: MicrosoftYaHei;
		color: #333333;
		line-height: 35px;
		text-align: center;
	}

	.form-content {
		padding: 30px;

		.form-content-box {
			padding: 20px 0;

			.form-content-shortitle {
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 10px;
				font-family: MicrosoftYaHei;
				height: 21px;
				display: flex;
				align-items: center;
				color: #333;

				&:before {
					content: '';
					width: 5px;
					height: 18px;
					border-radius: 4px;
					background: #2590e7;
					display: inline-block;
					margin-right: 7px;
				}

				span {
					padding-left: 10px;
					font-size: 14px;
					color: #999;
				}
			}

			.form-item-content {
				padding: 0 20px;

				.form-upload {
					.form-upload-defult {
						display: flex;
						align-items: center;

						.img {
							width: 80px;
							height: 80px;
							border-radius: 50%;
							display: flex;
							justify-content: center;
							align-items: center;
							color: #ffffff;
							background: rgba(0, 0, 0, 0.5);
						}

						.text {
							padding-left: 10px;

							p {
								text-align: left;
							}
						}
					}
				}

				.submitBtn {
					display: flex;
					justify-content: center;
				}
			}

			.divider {
				padding: 10px 0;
			}
		}
	}
}

.view-content {
	.view-info {
		padding-left: 30px;
		padding-bottom: 30px;
		display: flex;
		align-items: center;

		.avatar {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: #dcdadd;
			overflow: hidden;

			img {
				width: 100%;
			}
		}

		.info-text {
			padding-left: 30px;

			.title {
				font-size: 22px;
				font-weight: 600;
				color: #333;
			}

			p {
				padding: 2px 0;
			}
		}
	}

	.view-card {
		margin-bottom: 20px;
		padding: 30px;
		box-shadow: 6px 6px 10px #ccc;

		.view-card-title {
			font-size: 18px;
			font-width: 600;
			height: 30px;
			display: flex;
			align-items: center;
			color: #333;

			&:before {
				content: '';
				width: 5px;
				height: 18px;
				border-radius: 4px;
				background: #2590e7;
				display: inline-block;
				margin-right: 7px;
			}

			span {
				padding-left: 10px;
				font-size: 14px;
				color: #999;
			}
		}

		.view-card-list {
			.view-card-list-item {
				padding: 10px 0;
				display: flex;
				border-bottom: 1px dashed #ccc;

				&:last-child {
					border-bottom: none;
				}
				.label {
					width: 150px;
					font-size: 18px;
					font-weight: 600;
				}

				.value {
					font-size: 18px;
				}
			}
		}
	}
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
// .buttonText:hover{
//   background:#f1f8ff;
// }
.el-button.is-plain:hover {
	background: #f1f8ff;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
.el-icon-picture-outline:before {
	content: '\E75E';
	line-height: 45px;
}

/deep/ .el-dialog__header {
	text-align: center;
}
/deep/ .dialog-footer {
	text-align: center;
}
/deep/ .tabArea .el-radio-button .el-radio-button__inner {
	padding-right: 0;
	padding-left: 0;
	width: 105px !important;
}
.btn .blueColor {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.btn .el-button + .el-button {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
/deep/ .clearfix {
	.el-input .el-input__inner {
		border-radius: 0;
		border-right-width: 0;
		border: none;
		border-bottom: 2px solid #ccc;
	}

	.el-input .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select {
		float: left;
	}

	.el-select .el-input__inner {
		border-right-width: 0;
		border: none;
		border-radius: 0;
		border-bottom: 2px solid #ccc;
	}

	.el-select .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select .el-input__inner:focus {
		border-right-width: 1px;
	}

	.bot_content {
		p {
			display: flex;
			align-items: center;
			width: 100%;

			i {
				font-size: 22px;
			}

			span {
				font-size: 18px;
			}
		}
	}
}
</style>

<style>
.el-dialog__wrapper .el-dialog {
	margin-top: 25vh !important;
}
</style>
